import { backgroundImage } from 'html2canvas/dist/types/css/property-descriptors/background-image';

<template>
  <div class="main" @click="clickEvent">
    <div>
      <div class="title-tip">目前竞争激烈，快来为你的省份助力</div>
      <div class="name">电子木鱼功德榜</div>
    </div>
    <div>
      <div class="one-center"></div>

      <div class="center-rank" v-if="userInfo && userInfo.rank && userInfo.rank.length > 0">
        <div class="rank-list">
          <div class="one one-left">
            <img class="one-icon" src="@/assets/images/iuv/activity-sahre/icon-2.png" />
            <div class="one-tie tie-bg-2">{{ userInfo.rank[1].province_name }}</div>
            <div class="one-number">功德值 {{ userInfo.rank[1].virtue_num }}</div>
            <div class="one-rank">NO.2</div>
          </div>
          <div class="one">
            <img class="one-icon" src="@/assets/images/iuv/activity-sahre/icon-1.png" />
            <div class="one-tie tie-bg-1">{{ userInfo.rank[0].province_name }}</div>
            <div class="one-number">功德值 {{ userInfo.rank[0].virtue_num }}</div>
            <div class="one-rank">功德无量</div>
          </div>
          <div class="one one-right">
            <img class="one-icon" src="@/assets/images/iuv/activity-sahre/icon-3.png" />
            <div class="one-tie tie-bg-3">{{ userInfo.rank[2].province_name }}</div>
            <div class="one-number">功德值 {{ userInfo.rank[2].virtue_num }}</div>
            <div class="one-rank">NO.3</div>
          </div>
        </div>
        <img class="bg" src="@/assets/images/iuv/activity-sahre/rank.png" />
        <div class="bg2">
          <div class="bg2-btn">
            <div>捐献功德</div>
            <img class="bg2-icon" src="@/assets/images/iuv/activity-sahre/play-icon.png" />
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="bottom-name">Iuv桌面小组件</div>
      <div class="bottom-tip">此功能仅供娱乐</div>
    </div>
  </div>
</template>

<script>
  import API from '@/api/modules/App/widgets';
  import { mapGetters } from 'vuex';

  export default {
    async asyncData({ store, $http }) {
      try {
        const data = await $http.widgetsPost(API.VirtueRank);
        console.log(data, 'data--');
        store.commit('app/userInfo/SET_USER_INFO', data);
      } catch (err) {
        console.error(err);
      }
    },
    mounted() {
      this.$sdk.appCall(this.$sdk.callMethod.SET_TITLE, '一起参与木鱼挑战');
    },
    computed: {
      ...mapGetters('app/userInfo', ['userInfo']),
    },
    methods: {
      clickEvent() {
        let system = navigator.userAgent.toLowerCase();
        if (/(iPhone|iPad|iPod|iOS)/i.test(system)) {
          window.location.href = 'https://apps.apple.com/cn/app/1565296362';
        } else {
          this.$toast('安卓版开发中，敬请期待');
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .main {
    text-align: center;
    overflow: hidden;
    background: #f4f4f6;
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
  }
  .title-tip {
    height: 0.4rem;
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #656565;
    line-height: 0.4rem;
    margin-top: 0.3rem;
  }
  .one-center {
    width: 7.5rem;
    height: 7.5rem;
    background: url('~@/assets/images/iuv/activity-sahre/bg.png') no-repeat center center;
    background-size: 100% 100%;
    position: absolute;
    top: 1.23rem;
  }
  .name {
    height: 0.66rem;
    font-size: 0.48rem;
    font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
    font-weight: bold;
    color: #333333;
    line-height: 0.66rem;
    margin-top: 1.53rem;
  }
  .bottom {
    margin-bottom: 0.4rem;
  }
  .bottom-name {
    height: 0.32rem;
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #4c4c4c;
    line-height: 0.32rem;
  }
  .bottom-tip {
    height: 0.32rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 0.32rem;
  }

  .rank-list {
    width: 7.5rem;
    height: 3.03rem;
    position: absolute;
    top: -2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .one-left {
      position: relative;
      top: 1.02rem;
    }
    .one-right {
      position: relative;
      top: 1.5rem;
      right: -0.2rem;
    }
    .one {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      .one-icon {
        width: 1.88rem;
        height: 1.42rem;
        position: relative;
        top: 0.42rem;
        z-index: 1;
      }
      .tie-bg-1 {
        background: url('~@/assets/images/iuv/activity-sahre/tie-1.png') no-repeat center center;
      }
      .tie-bg-3 {
        background: url('~@/assets/images/iuv/activity-sahre/tie-3.png') no-repeat center center;
      }
      .tie-bg-2 {
        background: url('~@/assets/images/iuv/activity-sahre/tie-2.png') no-repeat center center;
      }
      .one-tie {
        position: relative;
        z-index: 2;
        background-size: 100% 100%;
        width: 2.2rem;
        height: 0.65rem;
        font-size: 0.28rem;
        font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.5rem;
        text-align: center;
      }
      .one-number {
        font-size: 0.28rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #4d4d4d;
        line-height: 0.4rem;
      }
      .one-rank {
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 0.33rem;
      }
    }
  }

  .center-rank {
    position: relative;
    margin: 0 auto;
    .bg {
      width: 7.5rem;
      height: 5.71rem;
      margin-top: 2.81rem;
    }
    .bg2 {
      width: 7.5rem;
      height: 2.35rem;
      background: url('~@/assets/images/iuv/activity-sahre/bg2.png') no-repeat center center;
      background-size: 100% 100%;
      position: relative;
      top: -1.5rem;
      overflow: hidden;
      .bg2-btn {
        margin: 0 auto;
        margin-top: 0.1rem;
        width: 3.34rem;
        height: 0.96rem;
        background: #6696f7;
        border-radius: 0.48rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.32rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 0.96rem;
        .bg2-icon {
          width: 0.36rem;
          height: 0.36rem;
          margin-right: 0.1rem;
        }
      }
    }
  }
</style>
